import dayjs from 'dayjs';
import { TextInput, Tip } from 'grommet';
import { SettingsOption } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiError } from '/src/api';
import {
  AddButton,
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  EquipmentUsageIcon,
  Line,
  Link,
  LoadingSpinner,
  Text,
} from '/src/components';
import { useEquipmentUsageStore, useGlobalStore } from '/src/context';
import { Equipment, EquipmentUsage } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId, DateString } from '/src/lib/types';
import { formatNumber, getPeriodFromDateString, getPeriodRange } from '/src/utils';

export const MeteredEquipmentUsagesList: React.FC<MeteredEquipmentUsagesListProps> = observer((props) => {
  const { equipment, equipmentUsagesList, ...boxProps } = props;

  const globalStore = useGlobalStore();
  const equipmentUsageStore = useEquipmentUsageStore();

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingCurrent, setIsUpdatingCurrent] = useState(false);
  const [isUpdatingPrevious, setIsUpdatingPrevious] = useState(false);

  const [currentValues, setCurrentValues] = useState<Record<string, string>>({
    total_kwh: '',
  });
  const [currentErrors, setCurrentErrors] = useState<Record<string, string>>({});

  const [previousValues, setPreviousValues] = useState<Record<string, string>>({
    total_kwh: '',
  });
  const [previousErrors, setPreviousErrors] = useState<Record<string, string>>({});

  const currentUsage = equipmentUsagesList?.[0];
  const previousUsage = equipmentUsagesList?.[1];
  const historicalUsage = equipmentUsagesList?.slice(2);

  const isCanada = equipment?.facility?.address_region?.country_id === CountryId.Canada;

  /* Methods */
  const updateCurrentFormValue = (key: string, value: any) => {
    setCurrentValues({
      ...currentValues,
      [key]: value,
    });
    if (currentErrors[key]) currentErrors[key] = '';
  };

  const updatePreviousFormValue = (key: string, value: any) => {
    setPreviousValues({
      ...previousValues,
      [key]: value,
    });
    if (previousErrors[key]) previousErrors[key] = '';
  };

  const updateCurrentUsage = async () => {
    if (!currentUsage) return;

    try {
      setIsUpdatingCurrent(true);

      const newUsages = {
        equipment_usage_id: currentUsage.id,
        total_kwh: parseFloat(currentValues.total_kwh),
      };
      await equipmentUsageStore.updateMeteredEquipmentUsage(newUsages);
      toast.success(toastMessages.updateEquipmentUsage.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateEquipmentUsage.error);
    } finally {
      setIsUpdatingCurrent(false);
    }
  };

  const updatePreviousUsage = async () => {
    if (!previousUsage) return;

    try {
      setIsUpdatingPrevious(true);

      const newUsages = {
        equipment_usage_id: previousUsage.id,
        total_kwh: parseFloat(previousValues.total_kwh),
      };
      await equipmentUsageStore.updateMeteredEquipmentUsage(newUsages);
      toast.success(toastMessages.updateEquipmentUsage.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateEquipmentUsage.error);
    } finally {
      setIsUpdatingPrevious(false);
    }
  };

  useEffect(() => {
    if (isLoading && !!equipmentUsagesList?.length) {
      setCurrentValues({
        ...currentValues,
        total_kwh: equipmentUsagesList[0]?.total_kwh,
      });
      if (equipmentUsagesList[1]) {
        setPreviousValues({
          ...previousValues,
          total_kwh: equipmentUsagesList[1].total_kwh,
        });
      }
      setIsLoading(false);
    }
  }, [isLoading, currentValues, previousValues, equipmentUsagesList]);

  const renderDataTable = (
    formValues: Record<string, string>,
    updateFormValue: (key: string, value: string) => void,
    equipmentUsages: EquipmentUsage[],
    showQuarterColumn?: boolean,
    isEditable?: boolean
  ) => {
    const quarterColumnWidth = 8;
    const leftoverWidth = 100 - quarterColumnWidth;
    const width = showQuarterColumn ? `${leftoverWidth / 1}%` : `${100 / 1}%`;

    const rowLabels = ['Total kWh'];

    const renderHeader = (rowLabels: string[], showQuarterColumn?: boolean) => (
      <Box row>
        <Box width="3rem" align="center" border={{ side: 'all', size: '0.4px', color: 'light-2' }} pad="small" />
        {showQuarterColumn && (
          <Box
            width={`${quarterColumnWidth}%`}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Text size="small" weight={300} toUpperCase>
              Period
            </Text>
          </Box>
        )}
        {rowLabels.map((label) => (
          <Box
            key={label}
            width={width}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Text size="small" weight={300} toUpperCase>
              {label}
            </Text>
          </Box>
        ))}
      </Box>
    );

    const renderEditRow = (equipmentUsage: EquipmentUsage) => (
      <Box row background="light-6">
        <Box
          width="3rem"
          justify="center"
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Link to={`/clients/${equipment?.client_id}/equipment/${equipment?.id}/usages/${equipmentUsage.id}`}>
            <Box align="center" justify="center">
              <Tip
                content={`View ${getPeriodFromDateString(
                  equipmentUsage.start_reporting_quarter,
                  isCanada
                )} Usage Details`}
              >
                <SettingsOption size="20px" color="accent-1" />
              </Tip>
            </Box>
          </Link>
        </Box>
        {showQuarterColumn && (
          <Box
            width={`${quarterColumnWidth}%`}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Text toUpperCase weight={500}>
              {getPeriodFromDateString(equipmentUsage.start_reporting_quarter, isCanada)}
            </Text>
          </Box>
        )}
        {['total_kwh'].map((valueKey, index) => (
          <Box
            key={index}
            width={width}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
            gap="xsmall"
          >
            <TextInput
              name={valueKey}
              value={formValues[valueKey]}
              type="number"
              textAlign="center"
              onChange={(e) => (valueKey ? updateFormValue(valueKey, e.target.value) : undefined)}
              style={{ background: 'white', paddingTop: '8px', paddingBottom: '8px' }}
            />
          </Box>
        ))}
      </Box>
    );

    const renderRow = (equipmentUsage: EquipmentUsage, rowIndex: number) => {
      const renderCell = (value: string | number, index: number, isPercent?: boolean, cellWidth?: string) => (
        <Box
          key={index}
          width={cellWidth || width}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Box row gap="xsmall">
            <Text toUpperCase>{value}</Text>
            <Text>{index === 0 && 'kWh'}</Text>
          </Box>
        </Box>
      );
      const row = [renderCell(formatNumber(equipmentUsage.total_kwh, true), 0, false)];

      return (
        <Box key={rowIndex} row background={rowIndex % 2 ? 'white' : 'light-6'}>
          <Box
            width="3rem"
            justify="center"
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Link to={`/clients/${equipment?.client_id}/equipment/${equipment?.id}/usages/${equipmentUsage.id}`}>
              <Box align="center" justify="center">
                <Tip
                  content={`View ${getPeriodFromDateString(
                    equipmentUsage.start_reporting_quarter,
                    isCanada
                  )} Usage Details`}
                >
                  <SettingsOption size="20px" color="accent-1" />
                </Tip>
              </Box>
            </Link>
          </Box>
          {showQuarterColumn && (
            <Box
              width={`${quarterColumnWidth}%`}
              align="center"
              border={{ side: 'all', size: '0.4px', color: 'light-2' }}
              pad="small"
            >
              <Text toUpperCase weight={500}>
                {getPeriodFromDateString(equipmentUsage.start_reporting_quarter, isCanada)}
              </Text>
            </Box>
          )}
          {row}
        </Box>
      );
    };

    return (
      <Box border={{ side: 'all', size: '0.4px', color: 'light-2' }}>
        {/* Header */}
        {renderHeader(rowLabels, showQuarterColumn)}

        {/* Current Usage */}
        {isEditable && renderEditRow(equipmentUsages[0])}

        {/* Historical Usages */}
        {!isEditable && equipmentUsages.map(renderRow)}
      </Box>
    );
  };

  const renderReportingDeadline = (quarter: DateString) => (
    <Box
      border={{ side: 'all', size: '1px', color: 'light-2' }}
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      align="center"
    >
      <Text size="xsmall" letterSpacing="0.5px" color="dark-1" toUpperCase>
        Reporting Deadline:
      </Text>
      <Text size="large" letterSpacing="0.5px">
        {dayjs(quarter).add(2, 'months').endOf('month').format('MMMM DD, YYYY')}
      </Text>
    </Box>
  );

  return (
    <Card {...boxProps}>
      <CardHeader title="Usage" icon={<EquipmentUsageIcon />}></CardHeader>
      <CardBody pad={!isLoading && !currentUsage && !previousUsage && !historicalUsage?.length ? 'none' : undefined}>
        {!isLoading ? (
          <Box gap="xsmall">
            {currentUsage && (
              <Box gap="medium">
                <Box row justify="between" align="end">
                  <Box gap="xsmall" justify="center">
                    <Box row gap="xsmall">
                      <Text size="xlarge" weight={500}>
                        {equipment?.last_active_reporting_quarter === currentUsage.start_reporting_quarter
                          ? 'Retirement'
                          : 'Latest'}{' '}
                        Period:
                      </Text>
                      <Text size="xlarge" weight={500} color="accent-1">
                        {getPeriodFromDateString(currentUsage.start_reporting_quarter, isCanada)}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="medium" weight={300}>
                        {getPeriodRange(currentUsage.start_reporting_quarter, isCanada)}
                      </Text>
                    </Box>
                  </Box>
                  {!currentUsage.is_finalized && (
                    <AddButton
                      background="accent-1"
                      color="white"
                      label={`SAVE ${getPeriodFromDateString(currentUsage.start_reporting_quarter, isCanada)} USAGE`}
                      tip={`Save changes to ${getPeriodFromDateString(currentUsage.start_reporting_quarter, isCanada)}`}
                      disabled={isUpdatingCurrent}
                      onClick={() => updateCurrentUsage()}
                    />
                  )}
                </Box>
                {renderDataTable(
                  currentValues,
                  updateCurrentFormValue,
                  [currentUsage],
                  false,
                  !currentUsage.is_finalized
                )}
              </Box>
            )}

            {currentUsage && previousUsage && <Line />}

            {previousUsage && (
              <Box gap="medium">
                <Box row justify="between" align="end">
                  <Box gap="xsmall" justify="center">
                    <Box row gap="xsmall">
                      <Text size="xlarge" weight={500}>
                        Previous Period:
                      </Text>
                      <Text size="xlarge" weight={500} color="accent-1">
                        {getPeriodFromDateString(previousUsage.start_reporting_quarter, isCanada)}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="medium" weight={300}>
                        {getPeriodRange(previousUsage.start_reporting_quarter, isCanada)}
                      </Text>
                    </Box>
                  </Box>
                  {!previousUsage.is_finalized && (
                    <AddButton
                      background="accent-1"
                      color="white"
                      label={`SAVE ${getPeriodFromDateString(previousUsage.start_reporting_quarter, isCanada)} USAGE`}
                      tip={`Save changes to ${getPeriodFromDateString(
                        previousUsage.start_reporting_quarter,
                        isCanada
                      )}`}
                      disabled={isUpdatingPrevious}
                      onClick={() => updatePreviousUsage()}
                    />
                  )}
                </Box>
                {renderDataTable(
                  previousValues,
                  updatePreviousFormValue,
                  [previousUsage],
                  false,
                  !previousUsage?.is_finalized
                )}
              </Box>
            )}

            {previousUsage && !!historicalUsage?.length && <Line />}

            {!!historicalUsage?.length && (
              <Box gap="medium">
                <Box gap="xsmall" justify="end">
                  <Text size="xlarge" weight={500}>
                    Historical Data
                  </Text>
                  <Text weight={300}>This data has already been submitted.</Text>
                </Box>
                {renderDataTable({}, () => {}, historicalUsage, true)}
              </Box>
            )}

            {!currentUsage && !previousUsage && !historicalUsage?.length && (
              <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
                <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                  No usage data found.
                </Text>
              </Box>
            )}
          </Box>
        ) : (
          <LoadingSpinner />
        )}
      </CardBody>
    </Card>
  );
});

export type MeteredEquipmentUsagesListProps = BoxProps & {
  equipment?: Equipment;
  equipmentUsagesList?: EquipmentUsage[];
};
