import {
  Equipment,
  TClientBrokerFeeRateId,
  TClientCommissionRateId,
  TClientId,
  TClientReportingPeriodId,
  TEquipmentId,
  TEquipmentTypeEERId,
  TEquipmentTypeId,
  TFSERegistrationStatusId,
  TFacilityId,
  TFinalSalePriceId,
  TFuelPathwayId,
  TProgramBrokerFeeRateId,
  TProgramId,
  TProgramRecCostId,
  TReportingPeriodTypeId,
} from '/src/lib/models';
import { DateString, Float, FloatString, Integer, IntegerString, Nullable } from '/src/lib/types';

export type TEquipmentUsageId = number;

export interface EquipmentUsage {
  id: TEquipmentUsageId;
  created_at: DateString;
  updated_at: DateString;
  import_file_name: Nullable<string>;
  import_row_number: Nullable<IntegerString>;

  client_reporting_period_id: TClientReportingPeriodId;
  reporting_period_type_id: TReportingPeriodTypeId;
  start_reporting_quarter: DateString;
  end_reporting_quarter: DateString;
  is_finalized: boolean;
  client_id: TClientId;
  facility_id: TFacilityId;
  program_id: TProgramId;
  equipment_id: Nullable<TEquipmentId>;
  equipment: Equipment;
  battery_capacity_rating_ah: Nullable<Integer>;
  voltage: Nullable<Integer>;
  is_book_and_claim_applied: boolean;
  is_metered: boolean;
  equipment_type_id: TEquipmentTypeId;
  uses_alternate_exd_displaced_formula: boolean;
  fse_registration_status_id: TFSERegistrationStatusId;
  equipment_type_eer_id: TEquipmentTypeEERId;
  eer: FloatString;
  ci_score: FloatString;
  ei: FloatString;
  fuel_pathway_id: TFuelPathwayId;
  cixd_standard_id: 29;
  cixd_standard: FloatString;
  program_rec_cost_id: TProgramRecCostId;
  program_rec_cost: FloatString;
  final_sale_price_id: TFinalSalePriceId;
  final_sale_price: FloatString;
  client_commission_rate_id: TClientCommissionRateId;
  percent_client_commission_rate: FloatString;
  program_broker_fee_rate_id: Nullable<TProgramBrokerFeeRateId>;
  client_broker_fee_rate_id: Nullable<TClientBrokerFeeRateId>;
  percent_broker_fee_rate: FloatString;
  charge_cycles_per_shift: Nullable<FloatString>;
  work_days_per_quarter: Nullable<FloatString>;
  shifts_per_day: Nullable<FloatString>;
  percent_charger_efficiency_rating: Nullable<Integer>;
  percent_charge_return_factor: Nullable<Integer>;
  percent_depth_of_discharge: Nullable<Integer>;
  battery_capacity_rating_kwh: Nullable<Float>;
  kwh_per_charge_cycle: Nullable<Float>;
  total_kwh: FloatString;
  cixd_reported: FloatString;
  exd_displaced: FloatString;
  credits_generated: FloatString;
  recs_required: Float;
  rec_cost: FloatString;
  total_value: FloatString;
  total_value_after_rec_cost: FloatString;
  broker_fee: FloatString;
  total_value_after_rec_cost_and_broker_fee: FloatString;
  percent_total_sales_commission_rate: FloatString;
  total_sales_commissions_paid: FloatString;
  percent_total_referral_commission_rate: FloatString;
  total_referral_commissions_paid: FloatString;
  total_commissions_paid: FloatString;
  commission_earned: FloatString;
  commission_earned_after_total_commissions_paid: FloatString;
  client_revenue: FloatString;
  comments: Nullable<string>;
}
