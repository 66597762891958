import { ReportingPeriodType, TClientId, TReportingPeriodTypeId } from 'src/lib/models';
import { DateString } from '../types';

export type TClientReportingPeriodId = number;

export interface ClientReportingPeriod {
  id: TClientReportingPeriodId;
  client_id: TClientId;
  reporting_period_type: ReportingPeriodType;
  reporting_period_type_id: TReportingPeriodTypeId;
  start_reporting_quarter: DateString;
  end_reporting_quarter: DateString;
  is_finalized: boolean;
  created_at: DateString;
  updated_at?: DateString;
}
