import { Button, Form, Layer } from 'grommet';
import { Close, List } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiError, ClientReportingPeriodService, TUpdateMultipleUnmeteredEquipmentUsagesRequest } from '/src/api';
import {
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  Input,
  ReportingPeriodDropdown,
  SaveButton,
  Text,
} from '/src/components';
import { useClientStore, useEquipmentUsageStore, useGlobalStore } from '/src/context';
import {
  ClientReportingPeriod,
  Facility,
  TClientId,
  TClientReportingPeriodId,
  TReportingPeriodTypeId,
} from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId, Float, Integer } from '/src/lib/types';
import { getFormErrors, pxToRem, updateFormValue } from '/src/utils';

export type TUpdateMultipleUnmeteredModalForm = {
  client_reporting_period_id: TClientReportingPeriodId;
  charge_cycles_per_shift: Float;
  shifts_per_day: Float;
  work_days_per_quarter: Float;
  percent_charger_efficiency_rating: Integer;
  percent_charge_return_factor: Integer;
  percent_depth_of_discharge: Integer;
};

export const UpdateMultipleUnmeteredModal: React.FC<UpdateMultipleUnmeteredModalProps> = observer((props) => {
  const { facility, isVisible, setIsVisible, refreshData, ...boxProps } = props;

  /** Stores **/
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();
  const equipmentUsageStore = useEquipmentUsageStore();

  /** Defaults **/
  const defaultFormValues = useRef({
    client_reporting_period_id: NaN,
    shifts_per_day: NaN,
    charge_cycles_per_shift: NaN,
    work_days_per_quarter: NaN,
    percent_charger_efficiency_rating: NaN,
    percent_charge_return_factor: NaN,
    percent_depth_of_discharge: NaN,
  });

  /** State **/
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedReportingPeriod, setSelectedReportingPeriod] = useState<ClientReportingPeriod>();

  const [formValues, setFormValues] = useState<TUpdateMultipleUnmeteredModalForm>(defaultFormValues.current);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Computed **/
  const hasValues =
    !!formValues.charge_cycles_per_shift ||
    !!formValues.percent_charge_return_factor ||
    !!formValues.percent_charger_efficiency_rating ||
    !!formValues.percent_depth_of_discharge ||
    !!formValues.shifts_per_day ||
    !!formValues.work_days_per_quarter;

  const canEditPeriod = !selectedReportingPeriod?.is_finalized;
  const isCanada = facility?.address_region?.country_id === CountryId.Canada;
  const selectedIndex = clientStore.reportingPeriods?.findIndex((p) => p.id === selectedReportingPeriod?.id) ?? -1;
  const previousPeriod = selectedIndex > -1 ? clientStore.reportingPeriods?.[selectedIndex + 1] : undefined;
  const nextPeriod = selectedIndex > -1 ? clientStore.reportingPeriods?.[selectedIndex - 1] : undefined;

  /** Methods **/

  /**
   * Set Values
   */
  const updateValue = (key: string, value: any) => {
    if (key === 'charge_cycles_per_shift' && (value as number) > 9.99) {
      setFormErrors({ ...formErrors, charge_cycles_per_shift: 'Value cannot exceed 9.99' });
    } else if (key === 'shifts_per_day' && (value as number) > 9.99) {
      setFormErrors({ ...formErrors, shifts_per_day: 'Value cannot exceed 9.99' });
    } else if (key === 'work_days_per_quarter' && (value as number) > 92) {
      setFormErrors({ ...formErrors, work_days_per_quarter: 'Value cannot exceed 92 days' });
    }
    updateFormValue(key, value, formValues, setFormValues, formErrors, setFormErrors);
  };

  /**
   * Updates multiple unmetered Equipment
   */
  const updateMultipleUnmetered = async () => {
    if (!facility.id || !selectedReportingPeriod) return;
    const errors = getFormErrors(
      {
        charge_cycles_per_shift: { label: 'Charge Cycles / Shift', required: true, max: 9.99, nonZero: true },
        shifts_per_day: { label: 'Shifts / Day', required: true, max: 9.99, nonZero: true },
        work_days_per_quarter: { label: 'Work Days / Quarter', required: true, max: 92, nonZero: true },
        percent_charger_efficiency_rating: {
          label: 'Charger Efficiency Rating',
          required: true,
        },
        percent_charge_return_factor: { label: 'Charge Return Factor', required: true },
        percent_depth_of_discharge: { label: 'Depth of Discharge', required: true },
      },
      formValues
    );
    if (errors) {
      setFormErrors(errors);
      return;
    }

    try {
      setIsUpdating(true);

      const updatedDetails: TUpdateMultipleUnmeteredEquipmentUsagesRequest = {
        facility_id: facility.id,
        client_reporting_period_id: selectedReportingPeriod.id,
        percent_charger_efficiency_rating: formValues.percent_charger_efficiency_rating,
        percent_charge_return_factor: formValues.percent_charge_return_factor,
        percent_depth_of_discharge: formValues.percent_depth_of_discharge,
        charge_cycles_per_shift: formValues.charge_cycles_per_shift,
        shifts_per_day: formValues.shifts_per_day,
        work_days_per_quarter: formValues.work_days_per_quarter,
      };

      await equipmentUsageStore.updateMultipleUnmeteredEquipmentUsages(updatedDetails);

      toast.success(toastMessages.updateNonMeteredEquipment.success);
      setIsVisible(false);

      await refreshData();
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateNonMeteredEquipment.error);
    } finally {
      setIsUpdating(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (facility.client_id) {
      clientStore
        .fetchClientReportingPeriods(facility.client_id, facility.reporting_period_type_id)
        .then((periods) => setSelectedReportingPeriod(periods?.[0]));
    }
  }, [facility.client_id, facility.reporting_period_type_id]);

  useEffect(() => {
    if (!isVisible) {
      setFormValues(defaultFormValues.current);
      setFormErrors({});
    }
  }, [isVisible]);

  /** Render **/
  return isVisible ? (
    <Layer onEsc={() => setIsVisible(false)} onClickOutside={() => setIsVisible(false)} background="transparent">
      <Box fill="horizontal">
        <Box direction="row" gap="medium">
          <Card {...boxProps}>
            {facility?.client_id && (
              <>
                <CardHeader title="Operation Details" icon={<List size="24px" color="brand" />}>
                  <Box direction="row" gap="1rem">
                    <ReportingPeriodDropdown
                      reportingPeriods={clientStore.reportingPeriods ?? []}
                      setSelectedReportingPeriod={setSelectedReportingPeriod}
                      selectedReportingPeriod={selectedReportingPeriod}
                      previousPeriod={previousPeriod}
                      nextPeriod={nextPeriod}
                      clientId={facility.client_id}
                      isCanada={isCanada}
                      required
                    />
                    <Button
                      alignSelf="center"
                      pad="0.5rem"
                      onClick={() => setIsVisible(false)}
                      icon={<Close size="20px" />}
                    />
                  </Box>
                </CardHeader>
                <Form>
                  <CardBody
                    saveButton={
                      !selectedReportingPeriod?.is_finalized ? (
                        <SaveButton
                          type="submit"
                          isLoading={isUpdating}
                          disabled={!hasValues}
                          label="SAVE"
                          onClick={() => updateMultipleUnmetered()}
                        />
                      ) : undefined
                    }
                    gap="medium"
                  >
                    {!canEditPeriod && (
                      <Box width={pxToRem(712.5)} height={pxToRem(140)} justify="center" align="center" pad="1rem">
                        <Text fontFamily="Lato, sans-serif">
                          This period has been finalized and can no longer be edited.
                        </Text>
                      </Box>
                    )}
                    {canEditPeriod && (
                      <Box gap="medium" width={pxToRem(712.5)}>
                        <Box direction="row" gap="small" justify="between">
                          <Box flex="grow">
                            <Input
                              label="Charging Cycles / Shift"
                              type="number"
                              value={formValues.charge_cycles_per_shift || ''}
                              setValue={(value) => updateValue('charge_cycles_per_shift', value)}
                              name="charge_cycles_per_shift"
                              error={formErrors['charge_cycles_per_shift']}
                              required
                            />
                          </Box>
                          <Box flex="grow">
                            <Input
                              label="Shifts / Day"
                              type="number"
                              value={formValues.shifts_per_day || ''}
                              setValue={(value) => updateValue('shifts_per_day', value)}
                              name="shifts_per_day"
                              error={formErrors['shifts_per_day']}
                              required
                            />
                          </Box>
                          <Box flex="grow">
                            <Input
                              label="Work Days / Quarter"
                              type="number"
                              value={formValues.work_days_per_quarter || ''}
                              setValue={(value) => updateValue('work_days_per_quarter', value)}
                              name="work_days_per_quarter"
                              error={formErrors['work_days_per_quarter']}
                              required
                            />
                          </Box>
                        </Box>
                        <Box direction="row" gap="small" justify="between">
                          <Box flex="grow">
                            <Input
                              label="Charger Efficiency Rating"
                              componentType="percent"
                              value={formValues.percent_charger_efficiency_rating || ''}
                              setValue={(value) => updateValue('percent_charger_efficiency_rating', value)}
                              name="percent_charger_efficiency_rating"
                              error={formErrors['percent_charger_efficiency_rating']}
                              required
                            />
                          </Box>
                          <Box flex="grow">
                            <Input
                              label="Charge Return Factor"
                              componentType="percent"
                              value={formValues.percent_charge_return_factor || ''}
                              setValue={(value) => updateValue('percent_charge_return_factor', value)}
                              name="percent_charge_return_factor"
                              error={formErrors['percent_charge_return_factor']}
                              required
                            />
                          </Box>
                          <Box flex="grow">
                            <Input
                              label="Depth of Discharge"
                              componentType="percent"
                              value={formValues.percent_depth_of_discharge || ''}
                              setValue={(value) => updateValue('percent_depth_of_discharge', value)}
                              name="percent_depth_of_discharge"
                              error={formErrors['percent_depth_of_discharge']}
                              required
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </CardBody>
                </Form>
              </>
            )}
          </Card>
        </Box>
      </Box>
    </Layer>
  ) : (
    <></>
  );
});

export type UpdateMultipleUnmeteredModalProps = BoxProps & {
  facility: Facility;
  isVisible?: boolean;
  setIsVisible: (isVisible: boolean) => void;
  refreshData: () => void | Promise<void>;
};
